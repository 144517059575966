import camelCase from 'lodash/camelCase'
import React, { useEffect, useRef, useState } from 'react'

import { Form } from '@scm/ui-component-form'
import { Bem } from '@scm/ui-core'

import { ChangeLocationBarProps } from './type'

const changeLocationBar = Bem('ChangeLocationBar')
export const ChangeLocationBar = ({
  variant,
  defaultValue,
  classNames,
  classNameButton,
  handleChangeLocation,
  placeholderText,
  submitText,
  resetValueToDefault = false,
}: ChangeLocationBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState(defaultValue || '')

  useEffect(() => {
    if (!defaultValue || (!resetValueToDefault && typeof value === 'undefined'))
      return
    setValue(defaultValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValueToDefault, defaultValue])

  useEffect(() => {
    if (variant !== 'commodities') {
      inputRef.current?.focus()
    }
  }, [variant])

  const changeLocation = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleChangeLocation(value)
  }

  return (
    <Form
      className={changeLocationBar({ variant: camelCase(variant) }, [
        classNames?.wrapper,
      ])}
      data-component="search-bar"
      method="get"
      onSubmit={e => changeLocation(e)}
    >
      <input
        ref={inputRef}
        className={changeLocationBar('Input', [classNames?.input])}
        placeholder={placeholderText}
        title="Zip code must be number"
        aria-label={placeholderText}
        type="text"
        onChange={event => setValue(event.target.value)}
        value={value}
        minLength={3}
        required
        pattern="^\d+$"
        data-testid="input-field"
      />

      <button
        className={changeLocationBar('SearchButton', [
          classNames?.searchBtn,
          classNameButton,
        ])}
        type="submit"
        aria-label="ChangeLocation"
        data-component="button"
      >
        {submitText}
      </button>
    </Form>
  )
}
